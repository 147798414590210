app.config(["$translateProvider", function($translateProvider) {
  var defaultLang = 'en';
  var deviceLanguage = localStorage.getItem('selectedLang') || navigator.language || defaultLang;
  var currentLang = deviceLanguage.split('-')[0];

  $translateProvider.useStaticFilesLoader({
    prefix: 'languages/',
    suffix: '.json'
  });

  localStorage.setItem('lang', currentLang);
  $translateProvider.preferredLanguage(currentLang);
}]);
