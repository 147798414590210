app.run(function(
  $rootScope, $translate, $location, AppFactory, DBFactory, LoaderFactory, SyncService, SidebarFactory, OverlayFactory,
  SplashLoaderFactory, AuthService, ReducedService, INDEX_URL, STATUS_BAR_COLOR, ApplicationRatingService,
  RequestPermissionsService, ErrFactory, FileSystemService, UnsupportedBrowsersDialogFactory, API, appVersion, LocalizationService){

  LocalizationService.localization(localStorage.getItem('lang'));

  window.api = API;
  $rootScope.Overlay = OverlayFactory;

  LoaderFactory.show();
  app.AM.promiseOfUserLoad = RequestPermissionsService.has("android.permission.WRITE_EXTERNAL_STORAGE")
  .then((ans) => {
    if (!ans.hasPermission) {
      return (RequestPermissionsService.storage(true))
      .catch((err) => {
        (new ErrFactory('errors.permissionForStorage').notify())
        .then(() => AppFactory.exit())
      })
    }
  })
  .then(() => {
    if (window.cordova) return FileSystemService.initFS();
  })
  .catch((err) => {
    if (!window.cordova && err instanceof ErrFactory.FsIsNotDefined) return null;
    else return Promise.reject(err);
  })
  .then(() => DBFactory)
  .then(() => {
    if (window.cordova) FileSystemService.cleanCacheFolder();
  })
  .catch((err) => console.error(err))
  .then(() => AuthService.loadUser())
  .then(user => AuthService.setAuthorizedUser(user).then(() => user))
  .then(user => {
    return user.update()
    .catch((err) => {
      if (ReducedService.login) return Promise.resolve();
      else {
        console.error(err);
        return user;
      }
    })
    .then(() => {
      document.body.classList.remove('cutout');
      if (ReducedService.login) {
        $location.path('/');
        LoaderFactory.hide();
        return user;
      } else {
        return AuthService.getAuthorizedUser() || user;
      }
    })
    .catch((err) => {
      console.error(err);
      if (!ReducedService.login) {
        if (!document.body.classList.contains('cutout')) document.body.classList.add('cutout');
        return AuthService.logout();
      }
    })
  })
  .catch(function(err) {
    if (!document.body.classList.contains('cutout')) document.body.classList.add('cutout');
    if (!ReducedService.login) $location.path('/sign-in');
    return LoaderFactory.hide();
  })
  .then(() => {
    document.addEventListener('resume', function ready() {
      AppFactory.checkNewVersion()
      .catch((err) => {
        if (err && err.store_url) {
          $location.path('/blocker');
          if (!$rootScope.$$phase) $rootScope.$apply();
        }
      });
    });
  });

  class RouteError extends ErrFactory {
    PATH = '/sign-in';
  }

  class SignInPath extends RouteError {}

  class PrivacyError extends RouteError {
    PATH = '/privacy-summary';
  };

  class IndexPath extends RouteError {
    PATH = INDEX_URL;
  }

  $rootScope.$on('$routeChangeStart', function(e, route, current) {
    route.resolve = route.resolve || {};
    if (!route.resolve.access) {
      route.resolve.access = ['AuthService', 'ReducedService', 'PopupRegisterService', async function(AuthService,ReducedService,PopupRegisterService) {
        if (route.params.preview) {
          return;
        }

        if (PopupRegisterService.length && PopupRegisterService.closeAll()) {
          return;
        }

        await app.AM.promiseOfUserLoad;

        let isSigning = AuthService.isSigning();
        let userFeatures = AuthService._authorizedUser && AuthService._authorizedUser.features;
        let routeRequired = route.required;

        if (isSigning && AuthService._authorizedUser && AuthService._authorizedUser.acceptedPrivacyPolicy === false) {
          let err = new PrivacyError;
          if (route.$$route.originalPath !== err.PATH) throw err;
        }

        switch (route.$$route.originalPath) {
          case '/':
            if (!ReducedService.login) {
              if (isSigning) {
                throw new IndexPath;
              } else {
                throw new SignInPath;
              }
            } else {
              throw new IndexPath;
            }
            break;
          case '/sign-in':
            if (!ReducedService.login) {
              if (isSigning) {
                throw new IndexPath;
              }
            } else if (ReducedService.login) {
              throw new IndexPath;
            }
            break;
          case '/restore-password':
            if (ReducedService.restore_password) {
              throw new IndexPath;
            }
            break;
        }

        if (routeRequired) {
          switch (routeRequired.signIn) {
            case 1:
              if (!isSigning) {
                if (!ReducedService.login) {
                  throw new SignInPath;
                }
              }
          }
          if (routeRequired.feature) {
            if (!AppFactory.isNetwork() || !userFeatures || !userFeatures[routeRequired.feature]) throw new IndexPath;
          }
        }

      }];
    }

    if ($rootScope.needEbsConfirm &&
        current && current.$$route.originalPath.includes('/ebs') &&
        route && !route.$$route.originalPath.includes('/sign-in')) e.preventDefault();
    else {
      AppFactory.setTemplateHeader(route);
      SidebarFactory.hide();
      SplashLoaderFactory.hide();
    }
  });

  $rootScope.$on("$routeChangeError", function (evt, to, from, error) {
    if (error instanceof RouteError) {
      $location.path(error.PATH);
    }
  });

  $rootScope.$on('$routeChangeSuccess', function(e, route) {
    if (route.title) {
      $translate(route.title)
      .then((title) => AppFactory.setTitle(title))
      .catch((err) => AppFactory.setTitle(err));
    }
  });

  app.AM.deviceready.then(() => {
    if (window.device && window.device.platform.toLowerCase() == 'android') {
      StatusBar.backgroundColorByHexString(STATUS_BAR_COLOR);
    }

    if (window.cordova) {
      window.open = (link, type = '_blank') => {
        cordova.InAppBrowser.open(link, type);
      }
    }

    if (!window.cordova) {
      const isFirefox = typeof InstallTrigger !== 'undefined';
      const isChrome  = window.navigator.userAgent.match("CriOS") ||       // Chrome on ios
                     (!!window.chrome &&                                   // is Chromium
                        window.navigator.vendor === "Google Inc." &&
                       (window.navigator.userAgent.indexOf("MSIE ") === -1 || navigator.userAgent.indexOf('Trident/') === -1));  //not IE
      if (!isChrome && !isFirefox) UnsupportedBrowsersDialogFactory.show();
    }

    return app.AM.promiseOfUserLoad;
  })
  .then(() => SyncService.syncAfterAppLoad())
  .then(() => {
      AppFactory.lastVersion = appVersion;
  })
  .then(() => app.AM.handleOpenURL)
  .then((url) => {
    if (url) {
      LoaderFactory.show();
      try {
        return url.match(/(.*):\/\/(.*)/)[2];
      } catch (e) {
        return Promise.reject();
      }
    }

    return Promise.reject();
  })
  .then((token) => AuthService.loginByEmsToken(token))
  .then(() => $location.path(INDEX_URL))
  .catch((e) => { if (e) console.error(e) })
  .then(() => LoaderFactory.hide())
  .then(() => {
    if (window.cordova) ApplicationRatingService.init()
  });
});
